<div class="layout-header" 
    appFragmentInView 
    fragment="Home" 
    [headerHeight]="headerHeight" #Home>
    <header #header>
        <p class="logo">Publeecity</p>

        <i [sch-icon]="'menu'" 
            class="hamburger" 
            (click)="showMobileMenu = !showMobileMenu"          
            cdkOverlayOrigin 
            #mobileTrigger="cdkOverlayOrigin">
        </i>

        <div class="links">
            <a class="link" 
                *ngFor="let link of links" 
                [class.active]="currentFragment === link"
                (click)="goToFragment(link)">
                {{link}}
            </a>
        </div>

        <div class="auth-links">
            <a class="link" [routerLink]="'/login'">Sign In</a>
            <button sch-button variant="outline" size="sm" [routerLink]="'/login'">
                Get started
                <i [sch-icon]="'arrow-diagonal'"></i>
            </button>
        </div>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="mobileTrigger"
            (overlayOutsideClick)="showMobileMenu = false"
            [cdkConnectedOverlayPositions]="dropDownMenuPosition"
            [cdkConnectedOverlayOpen]="showMobileMenu">
            <app-guest-layout-mobile-menu 
                [links]="links" 
                [currentFragment]="currentFragment" 
                (close)="goToFragment($event); showMobileMenu = false">
            </app-guest-layout-mobile-menu>
        </ng-template>
    </header>

    <div class="hero">
        <h2 class="title">
            Stunning AI-Powered Ads With <br/> Customer Reach on All Major Platforms
        </h2>
        <h4 class="sub-title">
            Publeecity provides simple ad creation and the mobility <br/>
            to distribute across multiple channels without hassle.
        </h4>
        <div class="d-flex justify-content-center">
            <button sch-button variant="outline" size="sm" class="borderless" [routerLink]="'/login'">
                Get Started For Free
            </button>
        </div>
    </div>
</div>
