import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralModalComponent } from '../../components/general-modal/general-modal.component';
import { FeedbackDialogConfig } from '../../models';
import { UxNgModule } from '@seech/ux-ng';
import { ControlsNgModule } from '@seech/controls-ng';

@Component({
  selector: 'app-feedback-dialog',
  standalone: true,
  imports: [
    CommonModule, 
    GeneralModalComponent, 
    UxNgModule, 
    ControlsNgModule
  ],
  templateUrl: './feedback-dialog.component.html',
  styleUrl: './feedback-dialog.component.scss',
})
export class FeedbackDialogComponent {
  @ViewChild('modalContainer') modalContainer!: GeneralModalComponent;
  config!: FeedbackDialogConfig;

  onAction() {
    this.config.onAction || this.modalContainer.closeModal();
  }
}
