/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ActionResponse } from '../model/models';
import { ManageUserOrganizationRequest } from '../model/models';
import { Member } from '../model/models';
import { Organization } from '../model/models';
import { OrganizationCreateRequest } from '../model/models';
import { OrganizationUpdateRequest } from '../model/models';
import { UserOrganizationBrands } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface OrganizationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param request 
     */
    organizationAddOrganization(request: OrganizationCreateRequest, extraHttpRequestParams?: any): Observable<Organization>;

    /**
     * 
     * 
     * @param organizationId 
     */
    organizationDeleteOrganization(organizationId: string, extraHttpRequestParams?: any): Observable<ActionResponse>;

    /**
     * 
     * 
     * @param organizationId 
     */
    organizationGetOrganizationDetails(organizationId: string, extraHttpRequestParams?: any): Observable<Organization>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    organizationGetOrganizationMembers(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Member>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    organizationGetOrganizations(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Organization>>;

    /**
     * 
     * 
     * @param userId 
     * @param page 
     * @param size 
     */
    organizationGetUserOrganizationBrands(userId: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<UserOrganizationBrands>>;

    /**
     * 
     * 
     * @param request 
     */
    organizationManageUserOrganizationBrands(request: ManageUserOrganizationRequest, extraHttpRequestParams?: any): Observable<ActionResponse>;

    /**
     * 
     * 
     * @param newOwnerUserId 
     */
    organizationTransferOrganizationOwnership(newOwnerUserId: string, extraHttpRequestParams?: any): Observable<Organization>;

    /**
     * 
     * 
     * @param request 
     */
    organizationUpdateOrganization(request: OrganizationUpdateRequest, extraHttpRequestParams?: any): Observable<Organization>;

}
