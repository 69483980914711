/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinates } from './coordinates';


export interface CityResponse { 
    id: string;
    coordinates: Coordinates;
}

