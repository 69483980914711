<div class="menu-wrapper">
    <div class="header">
        <div class="logo text-primary">Publeecity</div>
        <i [sch-icon]="'cancel'" class="icon" (click)="close.emit()"></i>
    </div>

    <ul>
        <li *ngFor="let link of links" 
            [class.active]="currentFragment === link"
            (click)="close.emit(link)">
            {{link}}
        </li>

        <li [routerLink]="'/login'" class="action-btn">
            <button sch-button variant="outline" class="w-100" (click)="close.emit()">
                Sign In
            </button>

            <button sch-button class="w-100" (click)="close.emit()">
                Get Started
            </button>
        </li>
    </ul>
</div>