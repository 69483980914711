import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UxNgModule } from '@seech/ux-ng';

@Component({
  selector: 'app-name-email',
  standalone: true,
  imports: [CommonModule, UxNgModule],
  templateUrl: './name-email.component.html',
  styleUrl: './name-email.component.scss',
})
export class NameEmailComponent {
  @Input() name!: string;
  @Input() email!: string;
  @Input() size: 'sm' | 'md' = 'md';
  @Input() loading = false;
}
