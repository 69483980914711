/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { Creative } from './creative';


export interface PublishMap { 
    id: string;
    projectId: string;
    creativeId: string;
    channelId: string;
    status: string;
    statusDate: Date;
    creative: Creative;
    project: Project;
}

