/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ActionResponse } from '../model/models';
import { InvitationCreateRequest } from '../model/models';
import { InvitationResponseRequest } from '../model/models';
import { OrganizationInvitation } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface InvitationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param invitation 
     */
    invitationInviteMember(invitation: InvitationCreateRequest, extraHttpRequestParams?: any): Observable<OrganizationInvitation>;

    /**
     * 
     * 
     * @param request 
     */
    invitationRespondToInvitation(request: InvitationResponseRequest, extraHttpRequestParams?: any): Observable<ActionResponse>;

}
