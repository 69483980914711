/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { SessionToken } from './sessionToken';
import { Connection } from './connection';


export interface Session { 
    token: SessionToken;
    globalToken: SessionToken;
    organizationName?: string;
    permissions?: Array<string>;
    role?: string;
    id: string;
    userId: string;
    userAgent?: string;
    loginOn: Date;
    lastSeenOn: Date;
    lastDetectedIP?: string;
    organizationId?: string;
    isLoggedOut?: boolean;
    connections: Array<Connection>;
    user: User;
}

