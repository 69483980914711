/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DemographicCreateRequest } from './demographicCreateRequest';
import { MediumCreateRequest } from './mediumCreateRequest';
import { IndustryCreateRequest } from './industryCreateRequest';


export interface ProductUpdateRequest { 
    name: string;
    description: string;
    price?: number;
    url?: string;
    color?: string;
    availability?: string;
    launchDate?: string;
    rateTypeId?: string;
    demographics?: Array<DemographicCreateRequest>;
    industries?: Array<IndustryCreateRequest>;
    media?: Array<MediumCreateRequest>;
    id: string;
}

