import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsNgModule } from '@seech/icons-ng';
import { UxNgModule } from '@seech/ux-ng';
import { HttpClientModule } from '@angular/common/http';
import { ControlsNgModule } from '@seech/controls-ng';
import { AuthNgModule, ACCOUNT_SERVICE_TOKEN } from '@seech/auth-ng';
import { CoreNgModule } from '@seech/core-ng';
import { environment } from '../environments/environment';
import { AccountClientService, SharedNgModule } from '@publeecity/shared-ng';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    HttpClientModule,
    LayoutModule, 
    BrowserAnimationsModule,
    SharedNgModule,
    IconsNgModule,
    UxNgModule,
    RouterModule.forRoot(appRoutes, { bindToComponentInputs: true }),
    //{ bindToComponentInputs: true }
    //This will bind the route parameter or route query parameter
    //to Input variable eliminating the need for
    //activatedRoute.snapshot.paramMap.get('someParameter') to get a route or query parameter

    CoreNgModule.forRoot({
      BASE_API_URL: environment.BASE_API_URL,
      REALTIME_HUB_URL: 'central-socket',
    }),

    ControlsNgModule.forRoot({
      googleAPIKey: environment.GOOGLE_API_KEY,
    }),
    
    AuthNgModule.forRoot({
      AUTH_API_URL: environment.AUTH_API_URL,
      AUTH_WEB_URL: environment.AUTH_WEB_URL,
      BASE_API_URL: environment.BASE_API_URL,
      CLIENT_ID: environment.CLIENT_ID,
      SESSION_TIMEOUT: { INTERVAL: 300000, COUNTDOWN: 60000 },
    })
  ],
  providers: [provideClientHydration(), { provide: ACCOUNT_SERVICE_TOKEN, useClass: AccountClientService }],
  bootstrap: [AppComponent],
})
export class AppModule {}
