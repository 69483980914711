/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ActionResponse } from '../model/models';
import { Product } from '../model/models';
import { ProductCreateRequest } from '../model/models';
import { ProductResponseModel } from '../model/models';
import { ProductUpdateRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProductServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param brandId 
     * @param request 
     */
    productAddProduct(brandId: string, request: ProductCreateRequest, extraHttpRequestParams?: any): Observable<Product>;

    /**
     * 
     * 
     * @param brandId 
     * @param id 
     */
    productDeleteProduct(brandId: string, id: string, extraHttpRequestParams?: any): Observable<ActionResponse>;

    /**
     * 
     * 
     * @param brandId 
     * @param productId 
     */
    productGetProductDetails(brandId: string, productId: string, extraHttpRequestParams?: any): Observable<ProductResponseModel>;

    /**
     * 
     * 
     * @param brandId 
     * @param query 
     * @param page 
     * @param size 
     */
    productGetProducts(brandId: string, query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Product>>;

    /**
     * 
     * 
     * @param brandId 
     * @param request 
     */
    productUpdateProduct(brandId: string, request: ProductUpdateRequest, extraHttpRequestParams?: any): Observable<Product>;

}
