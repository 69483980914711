import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Session, User } from '@publeecity/shared-ng';
import { ROUTE } from '../../constants/route';
import { Menu } from '../layout.model';

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrl: './standard-layout.component.scss',
})
export class StandardLayoutComponent implements OnInit {
  user!: User;
  session!: Session;
  menu: Menu[] = [
    {
      label: 'Home',
      icon: 'dashboard-circle',
      mobileIcon: 'home',
      route: ROUTE.HOME,
    },
    {
      label: 'Brands',
      icon: 'sparkle-fill',
      route: ROUTE.BRANDS,
    },
    {
      label: 'Projects',
      icon: 'cube',
      route: ROUTE.PROJECTS,
    },
    {
      label: 'Integrations ',
      icon: 'hub',
      route: ROUTE.INTEGRATIONS,
    },
    {
      label: 'Menu',
      icon: 'menu',
      forMobile: true,
    },
  ];

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.sessionService.getCurrentUserAsync().subscribe((user) => {
      if (user) {
        this.user = user;
        const session = this.sessionService.getCurrentSession();
        if (session) this.session = session;
      }
    });
  }
}
