/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Analytic } from '../model/models';
import { BlobMetaData } from '../model/models';
import { CountryPhoneCode } from '../model/models';
import { Currency } from '../model/models';
import { Diagnostic } from '../model/models';
import { EntityTag } from '../model/models';
import { FeatureFlag } from '../model/models';
import { FeatureFlagDto } from '../model/models';
import { FileResponse } from '../model/models';
import { FileUploadContext } from '../model/models';
import { InternetSpeed } from '../model/models';
import { Tag } from '../model/models';
import { UploadResult } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface GlobalServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param analytic 
     */
    globalAddAnalytic(analytic: Analytic, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param entityTag 
     */
    globalAddCategory(entityTag: Array<EntityTag>, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param diagnostic 
     */
    globalAddDiagnostic(diagnostic: Diagnostic, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param searchParam 
     */
    globalCategoryByName(searchParam?: string, extraHttpRequestParams?: any): Observable<Tag>;

    /**
     * 
     * 
     * @param tag 
     */
    globalCategoryExistAdd(tag: Tag, extraHttpRequestParams?: any): Observable<Tag>;

    /**
     * 
     * 
     * @param sourceContainerName 
     * @param sourceBlobName 
     * @param targetContainerName 
     * @param targetBlobName 
     */
    globalCloneFile(sourceContainerName?: string, sourceBlobName?: string, targetContainerName?: string, targetBlobName?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * 
     * 
     * @param modifiedName 
     */
    globalCloneFileByModifiedNameToSameContainer(modifiedName?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * 
     * 
     * @param modifiedName 
     */
    globalCloneFileByModifiedNameToStaging(modifiedName?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * 
     * 
     * @param url 
     */
    globalCloneFileByUrlToSameContainer(url?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * 
     * 
     * @param url 
     */
    globalCloneFileByUrlToStaging(url?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * 
     * 
     * @param sourceBlobNames 
     * @param sourceContainerName 
     * @param targetContainerName 
     */
    globalCloneFiles(sourceBlobNames: Array<string>, sourceContainerName?: string, targetContainerName?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * 
     * 
     * @param containerName 
     * @param modifiedName 
     */
    globalCommitFile(containerName?: string, modifiedName?: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param model 
     */
    globalCreateFeatureFlag(model: Array<FeatureFlag>, extraHttpRequestParams?: any): Observable<Array<FeatureFlag>>;

    /**
     * 
     * 
     * @param parentID 
     * @param blobID 
     */
    globalDelete(parentID: string, blobID: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param modifiedName 
     * @param isHardDelete 
     */
    globalDeleteFile(modifiedName: string, isHardDelete?: boolean, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param modifiedNames 
     * @param isHardDelete 
     */
    globalDeleteFiles(modifiedNames: Array<string>, isHardDelete?: boolean, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param parentID 
     */
    globalDeleteParent(parentID: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param containerName 
     * @param modifiedName 
     * @param startByte 
     * @param endByte 
     */
    globalDownloadBlobByModifiedName(containerName: string, modifiedName: string, startByte?: number, endByte?: number, extraHttpRequestParams?: any): Observable<FileResponse>;

    /**
     * 
     * 
     * @param modifiedName 
     * @param startByte 
     * @param endByte 
     */
    globalDownloadBlobByName(modifiedName: string, startByte?: number, endByte?: number, extraHttpRequestParams?: any): Observable<FileResponse>;

    /**
     * 
     * 
     * @param url 
     * @param startByte 
     * @param endByte 
     */
    globalDownloadBlobByUrl(url?: string, startByte?: number, endByte?: number, extraHttpRequestParams?: any): Observable<FileResponse>;

    /**
     * 
     * 
     * @param entityTag 
     */
    globalEditCategory(entityTag: Array<EntityTag>, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param containerName 
     * @param blobName 
     * @param expirationTimeInSecs 
     * @param value 
     * @param name 
     */
    globalGenerateBlobSasToken(containerName?: string, blobName?: string, expirationTimeInSecs?: number, value?: number, name?: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param containerName 
     * @param expirationTimeInSecs 
     * @param value 
     * @param name 
     */
    globalGenerateContainerSasToken(containerName?: string, expirationTimeInSecs?: number, value?: number, name?: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     */
    globalGetAllAnalytic(extraHttpRequestParams?: any): Observable<Array<Analytic>>;

    /**
     * 
     * 
     */
    globalGetAllDiagnostics(extraHttpRequestParams?: any): Observable<Array<Diagnostic>>;

    /**
     * 
     * 
     */
    globalGetAllFeatureFlag(extraHttpRequestParams?: any): Observable<Array<FeatureFlagDto>>;

    /**
     * 
     * 
     */
    globalGetAllFeatureFlagData(extraHttpRequestParams?: any): Observable<Array<FeatureFlag>>;

    /**
     * 
     * 
     * @param id 
     */
    globalGetAnalytic(id: string, extraHttpRequestParams?: any): Observable<Analytic>;

    /**
     * 
     * 
     * @param searchParam 
     */
    globalGetCategory(searchParam?: string, extraHttpRequestParams?: any): Observable<Array<Tag>>;

    /**
     * 
     * 
     * @param keyword 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    globalGetCountries(keyword?: string, page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<Array<CountryPhoneCode>>;

    /**
     * 
     * 
     * @param keyword 
     */
    globalGetCountriesByKeyword(keyword?: string, extraHttpRequestParams?: any): Observable<{ [key: string]: string; }>;

    /**
     * 
     * 
     * @param keyword 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    globalGetCurrencies(keyword?: string, page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<Array<Currency>>;

    /**
     * 
     * 
     * @param keyword 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    globalGetCurrenciesByCountryOrCurrencyCode(keyword?: string, page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<Array<Currency>>;

    /**
     * 
     * 
     * @param currencyCode 
     */
    globalGetCurrencyByCurrencyCode(currencyCode: string, extraHttpRequestParams?: any): Observable<Currency>;

    /**
     * 
     * 
     * @param id 
     */
    globalGetDiagnostic(id: string, extraHttpRequestParams?: any): Observable<Diagnostic>;

    /**
     * 
     * 
     * @param id 
     */
    globalGetEntityCategory(id: string, extraHttpRequestParams?: any): Observable<Array<Tag>>;

    /**
     * 
     * 
     * @param featureName 
     */
    globalGetFeatureFlagByName(featureName: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     */
    globalGetIPAddress(extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    globalGetPhoneCodes(page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<{ [key: string]: string; }>;

    /**
     * 
     * 
     * @param keyword 
     */
    globalGetPhoneCodesByKeyword(keyword?: string, extraHttpRequestParams?: any): Observable<{ [key: string]: string; }>;

    /**
     * 
     * 
     */
    globalGetSpeedByUrl(extraHttpRequestParams?: any): Observable<InternetSpeed>;

    /**
     * 
     * 
     * @param tags 
     * @param id 
     * @param appId 
     * @param entityName 
     */
    globalIncludeCategories(tags: Array<Tag>, id?: string, appId?: string, entityName?: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param tag 
     */
    globalIncludeCategory(tag: Tag, extraHttpRequestParams?: any): Observable<Tag>;

    /**
     * 
     * 
     * @param blobMeta 
     */
    globalPatch(blobMeta: BlobMetaData, extraHttpRequestParams?: any): Observable<BlobMetaData>;

    /**
     * 
     * 
     * @param model 
     */
    globalPatchFeatureFlag(model: FeatureFlag, extraHttpRequestParams?: any): Observable<FeatureFlag>;

    /**
     * 
     * 
     * @param blobMeta 
     */
    globalPost(blobMeta: BlobMetaData, extraHttpRequestParams?: any): Observable<BlobMetaData>;

    /**
     * 
     * 
     * @param containerName 
     * @param modifiedName 
     * @param totalChunks 
     */
    globalReassembleChunks(containerName?: string, modifiedName?: string, totalChunks?: number, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param file 
     */
    globalStageFile(file?: Blob, extraHttpRequestParams?: any): Observable<UploadResult>;

    /**
     * 
     * 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param blobName 
     * @param chunkNumber 
     */
    globalStageFileChunk(contentType?: string, contentDisposition?: string, headers?: Array<object>, length?: number, name?: string, fileName?: string, blobName?: string, chunkNumber?: number, extraHttpRequestParams?: any): Observable<UploadResult>;

    /**
     * 
     * 
     * @param parentID 
     * @param model 
     */
    globalUpload(parentID: string, model?: Array<FileUploadContext>, extraHttpRequestParams?: any): Observable<BlobMetaData>;

    /**
     * 
     * 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param containerName 
     * @param modifiedName 
     * @param chunkNumber 
     */
    globalUploadChunk(contentType?: string, contentDisposition?: string, headers?: Array<object>, length?: number, name?: string, fileName?: string, containerName?: string, modifiedName?: string, chunkNumber?: number, extraHttpRequestParams?: any): Observable<UploadResult>;

    /**
     * 
     * 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param containerName 
     */
    globalUploadFile(contentType?: string, contentDisposition?: string, headers?: Array<object>, length?: number, name?: string, fileName?: string, containerName?: string, extraHttpRequestParams?: any): Observable<UploadResult>;

    /**
     * 
     * 
     * @param files 
     * @param containerName 
     * @param supportPartialUploads 
     */
    globalUploadFiles(files?: Array<Blob>, containerName?: string, supportPartialUploads?: boolean, extraHttpRequestParams?: any): Observable<FileResponse>;

    /**
     * 
     * 
     * @param files 
     */
    globalUploadMessageFile(files?: Blob, extraHttpRequestParams?: any): Observable<Array<string>>;

}
