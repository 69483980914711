import { Component } from '@angular/core';

@Component({
  selector: 'app-guest-layout-footer',
  templateUrl: './guest-layout-footer.component.html',
  styleUrl: './guest-layout-footer.component.scss',
})
export class GuestLayoutFooterComponent {
  year = new Date().getFullYear();
}
