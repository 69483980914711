/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Currency } from '../model/models';
import { Gender } from '../model/models';
import { Industry2 } from '../model/models';
import { MetadataType } from '../model/models';
import { Platform } from '../model/models';
import { RateType } from '../model/models';
import { Role } from '../model/models';
import { Role2 } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LookupServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    lookupGetBrandRoles(extraHttpRequestParams?: any): Observable<Array<Role2>>;

    /**
     * 
     * 
     * @param keyword 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    lookupGetCurrencies(keyword?: string, page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<Array<Currency>>;

    /**
     * 
     * 
     */
    lookupGetGenders(extraHttpRequestParams?: any): Observable<Array<Gender>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupGetIndustries(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Industry2>>;

    /**
     * 
     * 
     */
    lookupGetMetaTypes(extraHttpRequestParams?: any): Observable<Array<MetadataType>>;

    /**
     * 
     * 
     */
    lookupGetOrganizationRoles(extraHttpRequestParams?: any): Observable<Array<Role>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupGetPlatforms(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Platform>>;

    /**
     * 
     * 
     */
    lookupGetRateTypes(extraHttpRequestParams?: any): Observable<Array<RateType>>;

}
