/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UploadResult { 
    url: string;
    modifiedName: string;
    size?: number;
    originalName: string;
    blobType: string;
    success: boolean;
    errorMessage?: string;
}

