/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type Ordinates = 0 | 1 | 1 | 2 | 2 | 3 | 4 | 4 | 7 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192 | 16384 | 32768 | 65535 | 65536 | 65536 | 65539 | 65543 | 131072 | 262144 | 524288 | 1048576 | 2097152 | 4194304 | 8388608 | 16777216 | 33554432 | 67108864 | 134217728 | 268435456 | 536870912 | 1073741824 | -2147483648 | -65536 | -1;

export const Ordinates = {
    NUMBER_0: 0 as Ordinates,
    NUMBER_1: 1 as Ordinates,
    NUMBER_12: 1 as Ordinates,
    NUMBER_2: 2 as Ordinates,
    NUMBER_22: 2 as Ordinates,
    NUMBER_3: 3 as Ordinates,
    NUMBER_4: 4 as Ordinates,
    NUMBER_42: 4 as Ordinates,
    NUMBER_7: 7 as Ordinates,
    NUMBER_8: 8 as Ordinates,
    NUMBER_16: 16 as Ordinates,
    NUMBER_32: 32 as Ordinates,
    NUMBER_64: 64 as Ordinates,
    NUMBER_128: 128 as Ordinates,
    NUMBER_256: 256 as Ordinates,
    NUMBER_512: 512 as Ordinates,
    NUMBER_1024: 1024 as Ordinates,
    NUMBER_2048: 2048 as Ordinates,
    NUMBER_4096: 4096 as Ordinates,
    NUMBER_8192: 8192 as Ordinates,
    NUMBER_16384: 16384 as Ordinates,
    NUMBER_32768: 32768 as Ordinates,
    NUMBER_65535: 65535 as Ordinates,
    NUMBER_65536: 65536 as Ordinates,
    NUMBER_655362: 65536 as Ordinates,
    NUMBER_65539: 65539 as Ordinates,
    NUMBER_65543: 65543 as Ordinates,
    NUMBER_131072: 131072 as Ordinates,
    NUMBER_262144: 262144 as Ordinates,
    NUMBER_524288: 524288 as Ordinates,
    NUMBER_1048576: 1048576 as Ordinates,
    NUMBER_2097152: 2097152 as Ordinates,
    NUMBER_4194304: 4194304 as Ordinates,
    NUMBER_8388608: 8388608 as Ordinates,
    NUMBER_16777216: 16777216 as Ordinates,
    NUMBER_33554432: 33554432 as Ordinates,
    NUMBER_67108864: 67108864 as Ordinates,
    NUMBER_134217728: 134217728 as Ordinates,
    NUMBER_268435456: 268435456 as Ordinates,
    NUMBER_536870912: 536870912 as Ordinates,
    NUMBER_1073741824: 1073741824 as Ordinates,
    NUMBER_MINUS_2147483648: -2147483648 as Ordinates,
    NUMBER_MINUS_65536: -65536 as Ordinates,
    NUMBER_MINUS_1: -1 as Ordinates
};

