/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TokenRequest } from './tokenRequest';


export interface IntegrationCreateRequest { 
    platformName: string;
    username: string;
    description?: string;
    status: string;
    isDefault: boolean;
    tokens: Array<TokenRequest>;
}

