import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'publeecity';

  // constructor(private router: Router) {
    
  //   // Log the router config for initial inspection
  //   console.log('Initial Router Configuration:', this.router.config);

  //   // Subscribe to router events to debug route transitions
  //   this.router.events.subscribe((event: any) => {
  //     if (event instanceof NavigationStart) {
  //       console.log('NavigationStart:', event.url);
  //     } else if (event instanceof RoutesRecognized) {
  //       console.log('RoutesRecognized:', event.url, event.state.root);
  //     } else if (event instanceof NavigationEnd) {
  //       console.log('NavigationEnd:', event.url);
  //     } else if (event instanceof NavigationError) {
  //       console.error('NavigationError:', event.error);
  //     }
  //   });
  // }

}
