/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Subscription { 
    id: string;
    userId: string;
    subscriptionId: string;
    paymentToken?: string;
    lastRenewedOn?: Date;
    expiresOn?: Date;
    frequency: string;
    isCurrent?: boolean;
    user: User;
}

