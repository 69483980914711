import { Component, Input } from '@angular/core';
import { Menu } from '../layout.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Input() footerMenu: Menu[] = [];
  year = new Date().getFullYear();
}
