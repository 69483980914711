/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandMembershipRequest } from './brandMembershipRequest';


export interface InvitationCreateRequest { 
    email?: string;
    userId?: string;
    organizationRoleId: string;
    brandRoleId: string;
    brandMemberships?: Array<BrandMembershipRequest>;
}

