<div class="demographics" [formGroup]="form" [attr.readonly]="readonly">
    <ng-container *ngIf="!loading; else loadingState">
        <seech-select
            [id]="'language'"
            [options]="languages"
            [label]="'Language'"
            [placeholder]="'Select language'"
            [selectedValue]="selectedLanguage"
            (selected)="onLanguageSelected($event)"
            [readonly]="readonly">
        </seech-select>
        <seech-select
            [id]="'gender'"
            [options]="genders"
            [label]="'Gender'"
            [placeholder]="'Select gender'"
            [readonly]="readonly"
            [formControlName]="genderFormControlName">
        </seech-select>
        <seech-input
            label="Min Age"
            id="minAge"
            type="number"
            [readonly]="readonly"
            [formControlName]="minAgeFormControlName">
        </seech-input>
        <seech-input
            label="Max Age"
            id="maxAge"
            type="number"
            [readonly]="readonly"
            [formControlName]="maxAgeFormControlName">
        </seech-input>
    </ng-container>

    <ng-template #loadingState>
        <div class="seech-select-placeholder">
            <seech-placeholder
                [width]="'100%'"
                [height]="'50px'"
            ></seech-placeholder>
        </div>
        <div class="seech-select-placeholder">
            <seech-placeholder
                [width]="'100%'"
                [height]="'50px'"
            ></seech-placeholder>
        </div>
        <div class="seech-input-placeholder">
            <seech-placeholder
                [width]="'100%'"
                [height]="'50px'"
            ></seech-placeholder>
        </div>
        <div class="seech-input-placeholder">
            <seech-placeholder
                [width]="'100%'"
                [height]="'50px'"
            ></seech-placeholder>
        </div>
    </ng-template>
</div>