import { Injectable } from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import { ConfirmationDialogConfig, FeedbackDialogConfig } from '../../models';
import { ConfirmationDialogComponent, FeedbackDialogComponent } from '../../dialogs';

@Injectable({
  providedIn: 'root', 
})
export class DialogService {

  constructor(private modalService: ModalService) {}

  showFeedback(config: FeedbackDialogConfig) {
    return this.modalService.open(
      FeedbackDialogComponent, 
      {
        data: {config}
      }
    );
  }

  showConfirmation(config: ConfirmationDialogConfig) {
    return this.modalService.open(
      ConfirmationDialogComponent, 
      {
        data: {config}
      }
    );
  }
  
}

