/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductResponse } from './productResponse';


export interface BrandWithProductsResponse { 
    brandName: string;
    products: Array<ProductResponse>;
}

