/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChannelMetadatum } from './channelMetadatum';


export interface MetadataType { 
    id: string;
    name: string;
    description?: string;
    isDisabled?: boolean;
    channelMetadata: Array<ChannelMetadatum>;
}

