/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CityResponse } from './cityResponse';
import { LanguageResponse } from './languageResponse';


export interface DemographicResponse { 
    id: string;
    title?: string;
    minAge?: number;
    maxAge?: number;
    gender?: string;
    isReusable?: boolean;
    cities: Array<CityResponse>;
    languages: Array<LanguageResponse>;
}

