/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { User } from './user';
import { Member } from './member';


export interface Organization { 
    user: User;
    id: string;
    referenceId?: string;
    name: string;
    ceoName?: string;
    description?: string;
    url?: string;
    employeeSize?: number;
    foundedOn?: string;
    geolocation?: Geometry;
    location?: string;
    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    isUrlVerified?: boolean;
    ownerId: string;
    members: Array<Member>;
}

