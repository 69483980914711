import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsNgModule } from '@seech/icons-ng';

@Component({
  selector: 'app-color-picker',
  standalone: true,
  imports: [CommonModule, IconsNgModule],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColorPickerComponent)
    }
  ]
})
export class ColorPickerComponent implements ControlValueAccessor {
  @Input() itemHeight!: string;
  @Input() itemWidth!: string;
  @Input() id!: string;
  @Input() readonly = false;
  @Input() disabled = false;

  _value!: string;
  colors: string[] = [];
  touched = false;

  get value(): string {
    return this._value;
  }
  set value(val: string) {
    this._value = val;
    this.colors = val.split(', ');
  }

  onChange = (event: any) => {
    //intentionally not implemented; method added to initialize variable
  };

  onTouched = () => {
    //intentionally not implemented; method added to initialize variable
  };

  writeValue(val:  string): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
