/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Industry2 } from './industry2';


export interface Industry3 { 
    brandIndustry: Industry2;
    id: string;
    brandId: string;
    industryId: string;
    createdOn: Date;
    createdBy: string;
    brand: Brand;
}

