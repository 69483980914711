import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Menu } from '../layout.model';
import { AccountClientService, Organization, OrganizationClientService, Session, User } from '@publeecity/shared-ng';
import { ToastService } from '@seech/ux-ng';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() session!: Session;
  @Input() sideNaveMenu: Menu[] = [];
  showMenu = false;
  disableMenuClose = false;
  dropDownMenuPosition: ConnectedPosition[] = [
    {
      originX: 'end', //Horizontal position should start at the end of origin element
      originY: 'top', //Vertical position should start at the bottom of origin element
      overlayX: 'end', //Menu should be placed at the start of horizontal position
      overlayY: 'bottom', //Menu should be placed at the start of vertical position
      offsetY: -10,
    },
  ];
  organizations?: Organization[];
  sub: Subscription = new Subscription();
  pageNumber = 0;

  constructor(private organizationService: OrganizationClientService,
              private accountClientService: AccountClientService,
              private toast: ToastService,
              private sessionService: SessionService,
              private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getOrganizations();
  }  

  getOrganizations(fetchNext = false) {
    const query = undefined;
    const size = 20;
    if(fetchNext) this.pageNumber++;
    this.sub.add(
      this.organizationService.getOrganizations(query, this.pageNumber, size)
        .subscribe({
          next: (res: Organization[]) => {
            this.organizations = fetchNext && this.organizations ? [...this.organizations, ...res] : res;
            if(fetchNext && res.length === 0) {
              this.pageNumber--;
            }
            this.cdr.detectChanges();
          },
          error: () => {
            if(fetchNext) this.pageNumber--;
          }
        })
    );
  }

  switchOrganization(id: string) {
    this.showMenu = false;
    this.sub.add(
      this.accountClientService.switchOrganization(id)
      .subscribe({
        next: (res: User) => {
          this.sessionService.startSession(res);
          this.toast.success('Organization switched sucessfully.');
        },
        error: (error) => {
          this.toast.error('Unable to switch organization. Please try again.');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
