import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ControlsNgModule, SelectOption, RadioDirective } from '@seech/controls-ng';
import { Geolocation } from '@seech/core-ng';
import { CityCreateRequest, Demographic3, DemographicClientService, DemographicResponse } from '@publeecity/shared-ng';
import { Subscription } from 'rxjs';
import { DemographicsControlComponent } from '../../controls';
import { UxNgModule } from '@seech/ux-ng';

type DemographFormFillMethod = 'new' | 'selectExisting' | 'cloneExisting';

@Component({
  selector: 'app-demographic-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlsNgModule,
    DemographicsControlComponent,
    RadioDirective,
    UxNgModule
  ],
  templateUrl: './demographic-form.component.html',
  styleUrl: './demographic-form.component.scss',
})
export class DemographicFormComponent implements OnInit, OnDestroy  {
  @Input() formGroup!: FormGroup;
  @Input() loading = false;
  @Input() isReadOnly = false;

  sub = new Subscription();
  _demographs: DemographicResponse[] = [];
  demographOptions: SelectOption[] = [];
  demographPage = 0;
  locationValue: any;

  get demographs(): DemographicResponse[] {
    return this._demographs;
  }
  set demographs(val: DemographicResponse[]) {
    this._demographs = val;
    this.demographOptions = val.map(x => {
      return {
        label: x.title,
        value: x.id,
      } as SelectOption;
    });
  }

  constructor(private demographicService: DemographicClientService) {}

  ngOnInit(): void {
    this.getReusableDemographics();

    this.sub.add(
      this.demographForm.get('cities')?.valueChanges.subscribe(value => {
        if(value) {
          this.locationValue = value?.map((x: any) => x.coordinates)[0];
        }
      })
    );
    this.sub.add(
      this.demographForm.get('demographFormFillMethod')?.valueChanges
      .subscribe((value: DemographFormFillMethod) => {
        if(value === 'new') {
          this.formGroup.reset();
        }
      })
    );
  }

  get demographForm(): FormGroup {
    return this.formGroup.get('demographics') as FormGroup;
  }

  get demographFormFillMethod(): DemographFormFillMethod {
    return this.demographForm.get('demographFormFillMethod')?.value as DemographFormFillMethod;
  }

  getReusableDemographics() {
    const query = undefined;
    const size = 20;
    this.sub.add(
      this.demographicService.getReusableDemographics(query, this.demographPage, size)
        .subscribe((res: DemographicResponse[]) => {
          this.demographs = res;
        })
    );
  }

  retrieveSelectedLocation(event: Geolocation) {
    const locationValue = this.demographForm.get('cities')?.value;
    const city = {
      coordinates: event.coordinates
    }  as CityCreateRequest
    this.demographForm.patchValue({
      cities: [...locationValue, city]
    });
  }

  onSelectDemograph(event: any) {
    const demographic = this.demographs.find(x => x.id === event.value);
    if(demographic) {
      const cities = demographic.cities.map(x => {
        return {
          id: x.id,
          coordinates: x.coordinates
        }  as CityCreateRequest
      });
      this.demographForm.patchValue({
        id: demographic.id,
        title: demographic.title,
        cities: cities,
        isReusable: demographic.isReusable,
        gender: demographic.gender,
        language: {
          id: demographic.languages[0]?.id,
          name: demographic.languages[0]?.name
        },
        minAge: demographic.minAge,
        maxAge: demographic.maxAge,
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
