/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role2 } from './role2';
import { Permission2 } from './permission2';


export interface RolePermission2 { 
    id: string;
    roleId: string;
    permissionId: string;
    createdOn: Date;
    permission: Permission2;
    role: Role2;
}

