/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Industry2 } from './industry2';
import { Product } from './product';


export interface Industry { 
    productIndustry: Industry2;
    id: string;
    productId: string;
    industryId: string;
    createdOn: Date;
    createdBy: string;
    product: Product;
}

