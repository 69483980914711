/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Demographic3 } from '../model/models';
import { DemographicCreateRequest } from '../model/models';
import { DemographicResponse } from '../model/models';
import { DemographicUpdateRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DemographicServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param request 
     */
    demographicAddDemographic(request: DemographicCreateRequest, extraHttpRequestParams?: any): Observable<Demographic3>;

    /**
     * 
     * 
     * @param demographicId 
     */
    demographicGetDemographicDetails(demographicId: string, extraHttpRequestParams?: any): Observable<DemographicResponse>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    demographicGetReusableDemographics(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<DemographicResponse>>;

    /**
     * 
     * 
     * @param request 
     */
    demographicUpdateDemographic(request: DemographicUpdateRequest, extraHttpRequestParams?: any): Observable<Demographic3>;

}
