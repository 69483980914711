import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsNgModule } from '@seech/icons-ng';

@Component({
  selector: 'app-add-button',
  standalone: true,
  imports: [CommonModule, IconsNgModule],
  templateUrl: './add-button.component.html',
  styleUrl: './add-button.component.scss',
})
export class AddButtonComponent {}
