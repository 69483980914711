export interface ConfirmationDialogConfig {
    title: string;
    body: string;
    actionBtnText?: string;
    cancelBtnText?: string;
    onAction: () => void;
    onCancel?: () => void;
}

export interface FeedbackDialogConfig {
    title: string;
    body: string;
    actionBtnText?: string;
    onAction?: () => void;
}