/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Product } from './product';


export interface Demographic { 
    id: string;
    productId: string;
    demographicId: string;
    createdOn: Date;
    createdBy: string;
    product: Product;
}

