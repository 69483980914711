/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { Demographic3 } from './demographic3';


export interface City { 
    id: string;
    demographicId: string;
    location?: string;
    coordinates?: Geometry;
    createdOn: Date;
    updatedOn: Date;
    demographic: Demographic3;
}

