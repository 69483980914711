import { Route } from '@angular/router';
import { CONSTANT } from './constants/constant';
import { CanActivateGuard } from '@seech/auth-ng';
import { GuestLayoutComponent } from './layout/guest-layout/guest-layout.component';
import { StandardLayoutComponent } from './layout/standard-layout/standard-layout.component';

const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: CONSTANT.ROUTE.OVERVIEW,
  },

  {
    path: '',
    component: StandardLayoutComponent,
    loadChildren: () =>
      import('./protected.module').then((m) => m.ProtectedModule),
    canActivate: [CanActivateGuard.canActivate],
    canActivateChild: [CanActivateGuard.canActivate],
  },
  {
    path: '',
    loadChildren: () => import('./public.module').then((m) => m.PublicModule),
  },

  {
    path: '',
    component: GuestLayoutComponent,
    loadChildren: () => import('./guest.module').then((m) => m.GuestModule),
  },

  {
    path: '**',
    component: GuestLayoutComponent,
    canActivate: [CanActivateGuard.redirectUnmatchedRoute],
    data: {
      destinationIfLoggedIn: CONSTANT.ROUTE.HOME, // Redirect authenticated users to home
      destinationIfNotLoggedIn: CONSTANT.ROUTE.OVERVIEW, // Redirect non-authenticated users to root
    },
  },
];

export const appRoutes: Route[] = [...routes];




