/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Role2 } from './role2';


export interface BrandRole { 
    role: Role2;
    id: string;
    userId: string;
    organizationId: string;
    brandRoleId: string;
    assignedOn: Date;
    user: User;
}

