/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProjectBudget { 
    budget: number;
    scheduleInterval: string;
    intervalInDays: number;
    runTime: string;
    occurrences: number;
    campaignStartDate: Date;
    campaignEndDate?: Date;
}

