/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanResponse } from './planResponse';
import { FeatureResponse } from './featureResponse';


export interface SubscriptionPlanResponse { 
    subscriptionKey: string;
    name: string;
    price: number;
    currency: string;
    description: string;
    isBestSeller: boolean;
    features: Array<FeatureResponse>;
    plans: Array<PlanResponse>;
}

