import { dev } from '@publeecity/shared-ng';

export const environment = {
  ...dev.environment,

  // all additional properties should come after this line
  AUTH_API_URL: 'https://dev-api-auth-seech.azurewebsites.net/api/',
  AUTH_WEB_URL: 'https://dev-auth.seech.com',
  ACCOUNT_WEB_URL: 'https://dev-account.seech.com',
  CDN_BASE_URL: 'https://dev-cdn.publeecity.com/web/',
  BASE_API_URL: 'https://dev-api-publeecity.azurewebsites.net',
  CLIENT_ID: 'EC2E82FA-F977-4EED-BD27-C6897943FB04',
  GOOGLE_API_KEY: 'AIzaSyBXozDBQk-HzM3s9bzeTc5XfvVGaYLNvnA',
  GOOGLE_ANALYTICS_ID: '',
  INTEGRATIONS: {
    DOMAIN: 'https://dev.publeecity.com',
    FACEBOOK_APPID: '',
    GOOGLE_CLIENT_ID: '',
    TIKTOK_APP_ID: '',
    LINKEDIN_CLIENT_ID: '',
  },
};
