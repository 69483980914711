/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandMember } from './brandMember';
import { RolePermission2 } from './rolePermission2';


export interface Role2 { 
    id: string;
    name: string;
    description?: string;
    createdOn: Date;
    rank?: number;
    brandMembers: Array<BrandMember>;
    rolePermissions: Array<RolePermission2>;
}

