/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Metadata } from './metadata';


export interface Medium2 { 
    brandMedia: Metadata;
    id: string;
    brandId: string;
    blobId: string;
    createdOn: Date;
    createdBy: string;
    brand: Brand;
}

