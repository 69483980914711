/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlanResponse { 
    planName: string;
    billingCycleInDays: number;
    effectivePrice: number;
    planDescription: string;
    isDefault: boolean;
}

