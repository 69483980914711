/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageCreateRequest } from './languageCreateRequest';
import { CityCreateRequest } from './cityCreateRequest';


export interface DemographicCreateRequest { 
    id?: string;
    title: string;
    minAge?: number;
    maxAge?: number;
    gender: string;
    isReusable?: boolean;
    cities: Array<CityCreateRequest>;
    languages: Array<LanguageCreateRequest>;
}

