import { Demographic3, Language } from "../models";

export const languages: Language[] = [
    {
        id: 'lang_001',
        demographicId: 'demo_001',
        name: 'English',
        createdOn: new Date('2021-01-12'),
        updatedOn: new Date('2022-02-15'),
        demographic: {} as Demographic3  // Placeholder to avoid circular reference
    },
    {
        id: 'lang_002',
        demographicId: 'demo_002',
        name: 'Dutch',
        createdOn: new Date('2019-05-22'),
        updatedOn: new Date('2021-06-05'),
        demographic: {} as Demographic3  // Placeholder to avoid circular reference
    },
    {
        id: 'lang_003',
        demographicId: 'demo_003',
        name: 'Mandarin',
        createdOn: new Date('2020-08-15'),
        updatedOn: new Date('2022-01-10'),
        demographic: {} as Demographic3  // Placeholder to avoid circular reference
    },
    {
        id: 'lang_004',
        demographicId: 'demo_004',
        name: 'Spanish',
        createdOn: new Date('2021-10-05'),
        updatedOn: new Date('2022-05-01'),
        demographic: {} as Demographic3  // Placeholder to avoid circular reference
    },
    {
        id: 'lang_005',
        demographicId: 'demo_005',
        name: 'French',
        createdOn: new Date('2020-04-18'),
        updatedOn: new Date('2021-03-29'),
        demographic: {} as Demographic3  // Placeholder to avoid circular reference
    }
];

export const demographics: Demographic3[] = [
    {
      id: 'demo_001',
      title: 'Youth Demographic',
      minAge: 18,
      maxAge: 25,
      gender: 'gender_001',
      organizationId: 'org_001',
      createdOn: new Date('2021-01-12'),
      createdBy: 'admin_001',
      isReusable: true,
      cities: [
        {
          id: 'city_001',
          demographicId: 'demo_001',
          coordinates: {
            srid: 4326,
            numGeometries: 1,
            isSimple: true,
            isValid: true,
            area: 305.1,
            length: 20.4,
            isRectangle: false,
            centroid: undefined,
            envelopeInternal: undefined
          },
          createdOn: new Date('2021-01-12'),
          updatedOn: new Date('2022-02-15'),
          demographic: {} as Demographic3  // Placeholder to avoid circular reference
        }
      ],
      languages: languages
    },
    {
      id: 'demo_002',
      title: 'Senior Citizens',
      minAge: 65,
      maxAge: 80,
      gender: 'gender_002',
      organizationId: 'org_002',
      createdOn: new Date('2019-05-22'),
      createdBy: 'admin_002',
      isReusable: false,
      cities: [
        {
          id: 'city_002',
          demographicId: 'demo_002',
          coordinates: {
            srid: 4326,
            numGeometries: 1,
            isSimple: true,
            isValid: true,
            area: 200.3,
            length: 15.0,
            isRectangle: false,
            centroid: undefined,
            envelopeInternal: undefined
          },
          createdOn: new Date('2019-05-22'),
          updatedOn: new Date('2021-06-05'),
          demographic: {} as Demographic3  // Placeholder to avoid circular reference
        }
      ],
      languages: languages
    },
    {
      id: 'demo_003',
      title: 'Middle-Aged Professionals',
      minAge: 30,
      maxAge: 50,
      gender: 'gender_003',
      organizationId: 'org_003',
      createdOn: new Date('2020-08-15'),
      createdBy: 'admin_003',
      isReusable: true,
      cities: [
        {
          id: 'city_003',
          demographicId: 'demo_003',
          coordinates: {
            srid: 4326,
            numGeometries: 1,
            isSimple: true,
            isValid: true,
            area: 300.5,
            length: 22.4,
            isRectangle: false,
            centroid: undefined,
            envelopeInternal: undefined
          },
          createdOn: new Date('2020-08-15'),
          updatedOn: new Date('2022-01-10'),
          demographic: {} as Demographic3  // Placeholder to avoid circular reference
        }
      ],
      languages: languages
    },
    {
      id: 'demo_004',
      title: 'Tech Enthusiasts',
      minAge: 20,
      maxAge: 40,
      gender: 'gender_004',
      organizationId: 'org_004',
      createdOn: new Date('2021-10-05'),
      createdBy: 'admin_004',
      isReusable: true,
      cities: [
        {
          id: 'city_004',
          demographicId: 'demo_004',
          coordinates: {
            srid: 4326,
            numGeometries: 1,
            isSimple: true,
            isValid: true,
            area: 150.2,
            length: 12.6,
            isRectangle: false,
            centroid: undefined,
            envelopeInternal: undefined
          },
          createdOn: new Date('2021-10-05'),
          updatedOn: new Date('2022-05-01'),
          demographic: {} as Demographic3  // Placeholder to avoid circular reference
        }
      ],
      languages: languages
    },
    {
      id: 'demo_005',
      title: 'Urban Families',
      minAge: 25,
      maxAge: 60,
      gender: 'gender_005',
      organizationId: 'org_005',
      createdOn: new Date('2020-04-18'),
      createdBy: 'admin_005',
      isReusable: false,
      cities: [
        {
          id: 'city_005',
          demographicId: 'demo_005',
          coordinates: {
            srid: 4326,
            numGeometries: 1,
            isSimple: true,
            isValid: true,
            area: 250.4,
            length: 18.1,
            isRectangle: false,
            centroid: undefined,
            envelopeInternal: undefined
          },
          createdOn: new Date('2020-04-18'),
          updatedOn: new Date('2021-03-29'),
          demographic: {} as Demographic3  // Placeholder to avoid circular reference
        }
      ],
      languages: languages
    }
  ];
    