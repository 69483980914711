import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { User } from '@publeecity/shared-ng';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() user!: User;
  showMenu = false;
  showMobileMenu = false;
  dropDownMenuPosition: ConnectedPosition[] = [
    {
      originX: 'end', //Horizontal position should start at the end of origin element
      originY: 'bottom', //Vertical position should start at the bottom of origin element
      overlayX: 'end', //Menu should be placed at the start of horizontal position
      overlayY: 'top', //Menu should be placed at the start of vertical position
      offsetY: 10,
    },
  ];

}
