/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoordinateSequence } from './coordinateSequence';
import { Geometry } from './geometry';
import { GeometryFactory } from './geometryFactory';
import { Coordinate } from './coordinate';
import { PrecisionModel } from './precisionModel';
import { Dimension } from './dimension';
import { Envelope } from './envelope';
import { OgcGeometryType } from './ogcGeometryType';


export interface Point { 
    factory?: GeometryFactory;
    userData?: object;
    srid: number;
    precisionModel?: PrecisionModel;
    numGeometries: number;
    isSimple: boolean;
    isValid: boolean;
    area: number;
    length: number;
    centroid?: Point;
    interiorPoint?: Point;
    pointOnSurface?: Point;
    envelope?: Geometry;
    envelopeInternal?: Envelope;
    isRectangle: boolean;
    coordinateSequence?: CoordinateSequence;
    coordinates?: Array<Coordinate>;
    numPoints: number;
    isEmpty: boolean;
    dimension: Dimension;
    boundaryDimension: Dimension;
    x: number;
    y: number;
    coordinate?: Coordinate;
    geometryType?: string;
    ogcGeometryType: OgcGeometryType;
    boundary?: Geometry;
    z: number;
    m: number;
}
export namespace Point {
}


