import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { OVERVIEW_FRAGMENTS } from '../../constants/route';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'app-guest-layout-header',
  templateUrl: './guest-layout-header.component.html',
  styleUrl: './guest-layout-header.component.scss',
})
export class GuestLayoutHeaderComponent implements AfterViewInit{
  @ViewChild('header') headerRef!: ElementRef;
  @ViewChild('Home') homeRef!: ElementRef;
  links = Object.values(OVERVIEW_FRAGMENTS);
  showMobileMenu = false;
  dropDownMenuPosition: ConnectedPosition[] = [
    {
      originX: 'end', //Horizontal position should start at the end of origin element
      originY: 'bottom', //Vertical position should start at the bottom of origin element
      overlayX: 'end', //Menu should be placed at the start of horizontal position
      overlayY: 'top', //Menu should be placed at the start of vertical position
      offsetY: 10,
    },
  ];
  currentFragment = OVERVIEW_FRAGMENTS.home;
  headerHeight = 50;

  constructor(private route: ActivatedRoute, 
              private renderer: Renderer2, 
              private router: Router) {}

  @HostListener('window:scroll', ['$event'])
    onScroll() {
      if(window.scrollY > this.headerHeight) {
        this.renderer.addClass(this.headerRef.nativeElement, 'bg-blue');
      }
      else {
        this.renderer.removeClass(this.headerRef.nativeElement, 'bg-blue');
      }
  }

  goToFragment(fragment: string) {
    if(fragment !== this.currentFragment)
    this.router.navigate([], {fragment: fragment, queryParams: {scroll: 1}});
  }

  ngAfterViewInit(): void {
    this.headerHeight = this.headerRef.nativeElement.offsetHeight;
    if(window.scrollY > this.headerHeight) {
      this.renderer.addClass(this.headerRef.nativeElement, 'bg-blue');
    } 
    this.route.fragment.subscribe((fragment) => {
      this.currentFragment = fragment ?? OVERVIEW_FRAGMENTS.home;
    });
  }
}
