/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChannelAttribute } from './channelAttribute';
import { ChannelMetadatum } from './channelMetadatum';
import { Category } from './category';
import { ChannelMetric } from './channelMetric';


export interface Channel { 
    id: string;
    categoryId: string;
    name: string;
    description?: string;
    groupName?: string;
    costPerUnit?: number;
    unitType?: string;
    isActive?: boolean;
    isRecommended?: boolean;
    category: Category;
    channelAttributes: Array<ChannelAttribute>;
    channelMetadata: Array<ChannelMetadatum>;
    channelMetrics: Array<ChannelMetric>;
}

