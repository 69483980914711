import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-guest-layout-mobile-menu',
  templateUrl: './guest-layout-mobile-menu.component.html',
  styleUrl: './guest-layout-mobile-menu.component.scss',
})
export class GuestLayoutMobileMenuComponent {
  @Input() links!: string[];
  @Input() currentFragment!: string;
  @Output() close = new EventEmitter<string>();

}
