/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaResponse } from './mediaResponse';
import { Coordinates } from './coordinates';
import { DemographicResponse } from './demographicResponse';
import { PlatformResponse } from './platformResponse';
import { IndustryResponse } from './industryResponse';


export interface BrandResponse { 
    id: string;
    name: string;
    organizationId: string;
    demographicId: string;
    description: string;
    url: string;
    preferredStyle: string;
    color: string;
    createdOn: Date;
    location?: string;
    coordinates: Coordinates;
    industries: Array<IndustryResponse>;
    platforms: Array<PlatformResponse>;
    media: Array<MediaResponse>;
    demographics: DemographicResponse;
}

