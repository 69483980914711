<div class="control-wrapper" [attr.readonly]="readonly">
    <p class="label">{{label}}</p>

    <div class="control" [ngStyle]="{'height': itemHeight}">
        <app-add-button *ngIf="(!readonly && !disabled)"
        class="media" (click)="fileUpload.triggerUpload()"
        [ngStyle]="{'flex-basis': itemWidth}"></app-add-button>

        <div class="media" *ngFor="let medium of media; let i = index"
        [ngStyle]="{'flex-basis': itemWidth}">
            <img [src]="medium" alt="selected media">

            <div class="action-wrapper" *ngIf="(!readonly && !disabled)" (click)="removeMedia(i)">
                <i [sch-icon]="'close'"></i>
            </div>
        </div>

        <seech-file-upload [id]="id" #fileUpload="seechFileUpload" [(ngModel)]="value"
        [maxSizeInMb]="1" [fileTypes]="['image/*', 'video/*']" [acceptMultiple]="multiple"
        [max]="max" (ngModelChange)="onChange(value); onTouched()">
        </seech-file-upload>
    </div>
</div>