/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Integration } from './integration';
import { Platform } from './platform';


export interface Token { 
    id: string;
    integrationId: string;
    platformName: string;
    accessToken: string;
    refreshToken?: string;
    expiresOn: Date;
    createdOn: Date;
    createdBy: string;
    integration: Integration;
    platformNameNavigation: Platform;
}

