/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { Creative } from './creative';
import { CampaignDemographic } from './campaignDemographic';


export interface Campaign { 
    id: string;
    projectId: string;
    creativeId: string;
    channelId: string;
    campaignName: string;
    startDate?: Date;
    endDate?: Date;
    budget?: number;
    status?: string;
    createdOn: Date;
    campaignDemographics: Array<CampaignDemographic>;
    creative: Creative;
    project: Project;
}

