/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel2 } from './channel2';
import { Campaign } from './campaign';
import { Demographic4 } from './demographic4';
import { Creative } from './creative';
import { Medium3 } from './medium3';
import { Intent2 } from './intent2';
import { PublishMap } from './publishMap';


export interface Project { 
    id: string;
    productId?: string;
    name: string;
    description: string;
    url?: string;
    startDate?: Date;
    endDate?: Date;
    generationType: string;
    budget?: number;
    email?: string;
    phone?: string;
    address?: string;
    scheduleInterval?: string;
    intervalInDays?: number;
    runTime?: string;
    occurrences?: number;
    campaignStartDate?: Date;
    campaignEndDate?: Date;
    createdOn: Date;
    createdBy: string;
    campaigns: Array<Campaign>;
    channels: Array<Channel2>;
    creatives: Array<Creative>;
    demographics: Array<Demographic4>;
    intents: Array<Intent2>;
    media: Array<Medium3>;
    publishMaps: Array<PublishMap>;
}

