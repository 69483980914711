import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconDirective, IconsNgModule } from '@seech/icons-ng';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { ModalService } from '@seech/ux-ng';

@Component({
  selector: 'app-general-modal',
  standalone: true,
  imports: [CommonModule, IconsNgModule, IconDirective],
  templateUrl: './general-modal.component.html',
  styleUrl: './general-modal.component.scss',
})
export class GeneralModalComponent implements OnInit, OnDestroy {
  @Input() label!: string;
  @Input() hasDivider = true;
  sub: Subscription = new Subscription();
  backButtonDisabled = true;

  constructor(
    private modalService: ModalService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    history.pushState(null, '');
    this.sub.add(
      this.location.subscribe(() => {
        this.backButtonDisabled = false;
        this.closeModal();
      })
    );
  }

  closeModal(data?: unknown) {
    this.modalService.close(null, data);
  }

  ngOnDestroy(): void {
    if (this.backButtonDisabled) history.back();
    this.sub.unsubscribe();
  }
}
