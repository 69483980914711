/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationInvitation } from './organizationInvitation';


export interface BrandMembership { 
    id: string;
    invitationId: string;
    brandId: string;
    roleId: string;
    createdOn: Date;
    createdBy: string;
    invitation: OrganizationInvitation;
}

