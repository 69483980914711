import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardLayoutComponent } from './standard-layout/standard-layout.component';
import { IconsNgModule, IconDirective } from '@seech/icons-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './side-nav/side-nav.component';
import { GuestLayoutComponent } from './guest-layout/guest-layout.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { SideNavDropdownComponent } from './side-nav-dropdown/side-nav-dropdown.component';
import { ScrollEndNotifierDirective, UxNgModule } from '@seech/ux-ng';
import { OverlayModule } from '@angular/cdk/overlay';
import { GuestLayoutHeaderComponent } from './guest-layout-header/guest-layout-header.component';
import { GuestLayoutFooterComponent } from './guest-layout-footer/guest-layout-footer.component';
import { GuestLayoutMobileMenuComponent } from './guest-layout-mobile-menu/guest-layout-mobile-menu.component';
import { FragmentInViewDirective } from '../directives';

const COMPONENTS = [StandardLayoutComponent, GuestLayoutComponent];

@NgModule({
  declarations: [
    ...COMPONENTS,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    HeaderMenuComponent,
    SideNavDropdownComponent,
    GuestLayoutHeaderComponent,
    GuestLayoutFooterComponent,
    GuestLayoutMobileMenuComponent,
  ],
  exports: [...COMPONENTS],
  imports: [
    RouterModule,
    CommonModule,
    IconsNgModule,
    IconDirective,
    ControlsNgModule,
    OverlayModule,
    FragmentInViewDirective,
    UxNgModule,
    ScrollEndNotifierDirective
  ],
})
export class LayoutModule {}
