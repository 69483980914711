import { Injectable } from '@angular/core';
import { OrganizationCreateRequest, OrganizationService, OrganizationUpdateRequest } from '../generated';

@Injectable({
  providedIn: 'root'
})
export class OrganizationClientService {

  constructor(private client: OrganizationService) { }

  /**
   * @param query 
   * @param page 
   * @param size 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getOrganizations(query?: string, page?: number, size?: number) {
    return this.client.organizationGetOrganizations(query, page, size);
  }

  /**
   * @param organizationId 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getOrganizationDetails(organizationId: string) {
    return this.client.organizationGetOrganizationDetails(organizationId);
  }

  /**
   * @param query 
   * @param page 
   * @param size 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getOrganizationMembers(query?: string, page?: number, size?: number) {
    return this.client.organizationGetOrganizationMembers(query, page, size);
  }

  /**
   * @param request 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  addOrganization(request: OrganizationCreateRequest) {
    return this.client.organizationAddOrganization(request);
  }

  /**
   * @param request 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  updateOrganization(request: OrganizationUpdateRequest) {
    return this.client.organizationUpdateOrganization(request);
  }

  /**
   * @param organizationId 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  deleteOrganization(organizationId: string) {
    return this.client.organizationDeleteOrganization(organizationId);
  }

  /**
   * @param newOwnerUserId 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  transferOrganizationOwnership(newOwnerUserId: string) {
    return this.client.organizationTransferOrganizationOwnership(newOwnerUserId);
  }

}
