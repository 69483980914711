import { Injectable } from '@angular/core';
import { AccountService, User } from '../generated';
import { HttpClient, HttpContext } from '@angular/common/http';
import { SKIP_OPTION } from '@seech/auth-ng';
import { HttpRequestOptions } from '@seech/auth-ng/lib/models';

@Injectable({
  providedIn: 'root'
})
export class AccountClientService {

  constructor(private client: AccountService, private http: HttpClient) { }

  /**
   * Initiates a login request.
   * @returns An Observable that emits a response indicating the success of the login operation.
   */
  login(option: HttpRequestOptions) {
    const context = (option.context ?? new HttpContext()).set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
    return this.http.get<User>('/api/Account/login', { headers: option.headers as any, context: context as any, observe: 'response' });
  }

  /**
   * Initiates a signup request.
   * @param User The user information to be used for signup.
   * @returns An Observable that emits a response indicating the success of the signup operation.
   */
  signup(body: User, option: HttpRequestOptions) {
    const context = (option.context ?? new HttpContext()).set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
    return this.http.post<User>('/api/Account/signup', body, { headers: option.headers as any, context: context as any, observe: 'response' });
  }

  /**
   * @param organizationId 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  switchOrganization(organizationId: string) {
    return this.client.accountSwitchOrganization(organizationId);
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  leaveOrganization() {
    return this.client.accountLeaveOrganization();
  }
}
