import { ROUTE } from "./route";

export const CONSTANT = {
    ROUTE,
    roles: [
        'Users',
        'Basic',
        'Owner',
        'Creators',
        'Agency'
    ]
}