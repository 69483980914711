/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';


export interface Intent2 { 
    id: string;
    projectId: string;
    intentId: string;
    createdOn: Date;
    createdBy: string;
    project: Project;
}

