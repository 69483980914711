/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectContactInfo } from './projectContactInfo';
import { DemographicCreateRequest } from './demographicCreateRequest';
import { ProjectBudget } from './projectBudget';


export interface ProjectCreateRequest { 
    id?: string;
    name: string;
    description: string;
    url?: string;
    generationType: string;
    demographics?: DemographicCreateRequest;
    contactInfo?: ProjectContactInfo;
    projectBudget?: ProjectBudget;
}

