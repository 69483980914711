<footer class="footer">
    <div class="footer-info">
        <span class="name">Publeecity </span>
        <span class="company">by SEECH</span> 
        <span class="copy">&copy;</span> 
        <span class="year"> {{year}} </span>
    </div>

    <div class="footer-links">
        <div class="link">Legal</div>
        <div class="divider"></div>
        <div class="link">How it works</div>
        <div class="divider"></div>
        <div class="link">Feedback</div>
    </div>
</footer>

<footer class="mobile-footer">
    <div class="footer-menu-item" *ngFor="let menu of footerMenu" 
    [routerLink]="menu.route" routerLinkActive="active">
        <i class="icon" [sch-icon]="menu.mobileIcon || menu.icon!"></i>
        <p class="label">{{menu.label}}</p>
    </div>
</footer>
