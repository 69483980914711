/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DemographicCreateRequest } from './demographicCreateRequest';
import { PlatformCreateRequest } from './platformCreateRequest';
import { MediumCreateRequest } from './mediumCreateRequest';
import { Coordinates } from './coordinates';
import { IndustryCreateRequest } from './industryCreateRequest';


export interface BrandUpdateRequest { 
    name: string;
    description?: string;
    url?: string;
    coordinates?: Coordinates;
    preferredStyle?: string;
    color?: string;
    demographics?: Array<DemographicCreateRequest>;
    industries?: Array<IndustryCreateRequest>;
    media?: Array<MediumCreateRequest>;
    platforms?: Array<PlatformCreateRequest>;
    id: string;
}

