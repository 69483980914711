/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { BrandMember } from './brandMember';
import { Demographic2 } from './demographic2';
import { Industry3 } from './industry3';
import { BrandPlatform } from './brandPlatform';
import { Medium2 } from './medium2';
import { Product } from './product';


export interface Brand { 
    products: Array<Product>;
    id: string;
    organizationId: string;
    name: string;
    description?: string;
    url?: string;
    geolocation?: Geometry;
    location?: string;
    preferredStyle?: string;
    createdOn: Date;
    createdBy: string;
    color?: string;
    brandMembers: Array<BrandMember>;
    brandPlatforms: Array<BrandPlatform>;
    demographics: Array<Demographic2>;
    industries: Array<Industry3>;
    media: Array<Medium2>;
}

