/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Integration } from './integration';
import { Token } from './token';


export interface Platform { 
    id: string;
    name: string;
    description?: string;
    logo?: string;
    isDisabled: boolean;
    createdOn: Date;
    createdBy?: string;
    integrations: Array<Integration>;
    tokens: Array<Token>;
}

