/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Analytic { 
    id: string;
    actionType: string;
    appId: string;
    requestOrigin?: string;
    currentURL?: string;
    referrer?: string;
    createdOn: Date;
    createdBy?: string;
    eventLocation?: string;
    eventLabel?: string;
    eventAction?: string;
    eventValue?: string;
    ip?: string;
    coordinates?: string;
}

