/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Role2 } from './role2';


export interface BrandMember { 
    id: string;
    brandId: string;
    userId: string;
    roleId: string;
    createdOn: Date;
    createdBy: string;
    brand: Brand;
    role: Role2;
}

