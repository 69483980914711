<div class="search-row mb-0">
    <seech-input
        placeholder="Search ..."
        id="search"
        [icon]="'search'"
        class="search-input"
        alignIcon="left"
        (input)="search.emit(searchKeywords)"
        [(ngModel)]="searchKeywords">
    </seech-input>

    <button sch-button size="sm" *ngIf="btnLabel" shape="normal" (click)="btnClick.emit()">
        <i *ngIf="btnIcon" [sch-icon]="btnIcon"></i>
        {{ btnLabel }}
    </button>
</div>  