/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { Connection } from './connection';
import { BrandRole } from './brandRole';
import { Subscription } from './subscription';
import { Session } from './session';


export interface User { 
    organization: Organization;
    id: string;
    globalId: string;
    username: string;
    name: string;
    email: string;
    imageURL?: string;
    notificationLastViewedOn?: Date;
    subscriptionKey?: string;
    subscriptionStatus?: string;
    subscriptionExpiresOn?: Date;
    primaryOrganizationId?: string;
    createdOn: Date;
    lastSeenOn: Date;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    brandRoles: Array<BrandRole>;
    connections: Array<Connection>;
    sessions: Array<Session>;
    subscriptions: Array<Subscription>;
}

