/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { Organization } from './organization';
import { User } from './user';


export interface Member { 
    user: User;
    name?: string;
    email?: string;
    roleName?: string;
    id: string;
    organizationId: string;
    userId: string;
    roleId: string;
    createdOn: Date;
    createdBy: string;
    organization: Organization;
    role: Role;
}

