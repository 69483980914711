<nav class="nav-header">
    <div class="nav-header-info">
        <h2 class="logo">Publeecity</h2>
        <p class="slogan">Brand visibility with simplicity</p>
    </div>

    <div class="combo-button">
        <seech-combo-button
            [id]="'header-combo-button'"
            [variant]="'outline'"
            [size]="'md'"
            [dropdownItems]="[]"
            (toggleClicked)="showMenu = !showMenu"
            cdkOverlayOrigin 
            #trigger="cdkOverlayOrigin">
            <div class="combo-label" label>
                <div class="initial">{{user.email.charAt(0).toUpperCase()}}</div>
                <p class="email">{{user.email}}</p>
            </div>
        </seech-combo-button>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            (overlayOutsideClick)="showMenu = false"
            [cdkConnectedOverlayPositions]="dropDownMenuPosition"
            [cdkConnectedOverlayOpen]="showMenu"
        >
            <app-header-menu [user]="user" (selected)="showMenu = false"></app-header-menu>
        </ng-template>
    </div>
</nav>

<nav class="mobile-header">
    <div class="mobile-header-info">Publeecity</div>

    <div class="mobile-header-account"   
        (click)="showMobileMenu = !showMobileMenu"          
        cdkOverlayOrigin 
        #mobileTrigger="cdkOverlayOrigin">
        <div> {{user.email.charAt(0).toUpperCase()}} </div>
    </div>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="mobileTrigger"
        (overlayOutsideClick)="showMobileMenu = false"
        [cdkConnectedOverlayPositions]="dropDownMenuPosition"
        [cdkConnectedOverlayOpen]="showMobileMenu"
        >
        <app-header-menu [user]="user" (selected)="showMobileMenu = false"></app-header-menu>
    </ng-template>
</nav>


