/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel } from './channel';


export interface Category { 
    id: string;
    name: string;
    description?: string;
    isActive?: boolean;
    channels: Array<Channel>;
}

