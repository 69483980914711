/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';
import { Token } from './token';


export interface Integration { 
    id: string;
    platformName: string;
    organizationId: string;
    username: string;
    description?: string;
    status: string;
    lastUsed?: Date;
    isDefault: boolean;
    isDisabled: boolean;
    createdOn: Date;
    createdBy: string;
    platformNameNavigation: Platform;
    tokens: Array<Token>;
}

