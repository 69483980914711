/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Creative } from './creative';


export interface ImageCreative { 
    id: string;
    creativeId: string;
    width: number;
    height: number;
    blobId?: string;
    altText?: string;
    createdOn: Date;
    updatedOn: Date;
    creative: Creative;
}

