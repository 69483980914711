/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Demographic3 } from './demographic3';
import { IndustryResponse } from './industryResponse';


export interface ProductResponseModel { 
    id: string;
    brandId: string;
    name: string;
    description: string;
    url?: string;
    price?: number;
    color?: string;
    demographicId: string;
    createdOn: Date;
    launchDate?: string;
    availability?: string;
    rateTypeId?: string;
    industries: Array<IndustryResponse>;
    demographics: Demographic3;
}

