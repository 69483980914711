<footer class="footer">
    <div class="left">
        <span class="logo">PUBLEECITY</span>
        <span class="company">by SEECH</span>
        <span class="copyright-year"> &copy; {{year}} </span>
    </div>

    <div class="footer-links">
        <div class="link">Legal</div>
        <div class="divider"></div>
        <div class="link">How it works</div>
        <div class="divider"></div>
        <div class="link">Feedback</div> 
    </div>
</footer>
