/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';
import { City } from './city';


export interface Demographic3 { 
    id: string;
    title?: string;
    minAge?: number;
    maxAge?: number;
    gender?: string;
    organizationId: string;
    createdOn: Date;
    createdBy: string;
    isReusable?: boolean;
    cities: Array<City>;
    languages: Array<Language>;
}

