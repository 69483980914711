import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@publeecity/shared-ng';
import { Menu } from '../layout.model';
import { ROUTE } from '../../constants/route';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrl: './header-menu.component.scss',
})
export class HeaderMenuComponent {
  @Input() user!: User;
  @Output() selected = new EventEmitter<void>();
  pages: Menu[] = [
    {
      label: 'Organizations',
      route: ROUTE.ORGANIZATION,
    },
    {
      label: 'Subscriptions',
      route: ROUTE.SUBSCRIPTION,
    },
    {
      label: 'Payments',
      route: ROUTE.HOME,
    },
    {
      label: 'Preferences',
      route: ROUTE.HOME,
    }
  ];
  accountWebURL = environment.ACCOUNT_WEB_URL;

  constructor(private router: Router, private sessionService: SessionService) {}

  goToRoute(route: string) {
    this.router.navigate([route]);
    this.selected.emit();
  }

  signout() {
    this.sessionService.endSession();
    this.router.navigate([ROUTE.OVERVIEW]);
  }
}
