/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RolePermission } from './rolePermission';


export interface Permission { 
    id: string;
    name: string;
    description?: string;
    createdOn: Date;
    updatedOn: Date;
    rolePermissions: Array<RolePermission>;
}

