<ng-container *ngIf="!loading; else loadingState">
    <div class="wrapper {{size}}">
        <div class="avatar">
            <span class="text-primary">
                {{ name.charAt(0).toUpperCase() }}
            </span>
        </div>
        <p class="name"> {{ name }} </p>
        <p class="email"> {{ email }} </p>
    </div>   
</ng-container>

<ng-template #loadingState>
    <div class="wrapper {{size}}">
        <seech-placeholder
            [width]="'100%'"
            [height]="'100%'"
            [shape]="'circle'"
            class="avatar-loader"
        ></seech-placeholder>
        <p class="name">
            <seech-placeholder
                [width]="'70px'"
                [height]="'14px'"
            ></seech-placeholder>
        </p>
        <p class="email">
            <seech-placeholder
                [width]="'130px'"
                [height]="'14px'"
            ></seech-placeholder>
        </p>
    </div>
</ng-template>
