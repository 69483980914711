import { Injectable } from '@angular/core';
import { DemographicCreateRequest, DemographicService, DemographicUpdateRequest } from '../generated';

@Injectable({
  providedIn: 'root'
})
export class DemographicClientService {

  constructor(private client: DemographicService) { }

  /**
   * @param query 
   * @param page 
   * @param size 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getReusableDemographics(query?: string, page?: number, size?: number,) {
    return this.client.demographicGetReusableDemographics(query, page, size);
  }

  /**
   * @param request 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  addDemographic(request: DemographicCreateRequest) {
    return this.client.demographicAddDemographic(request);
  }

  /**
   * @param request 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  updateDemographic(request: DemographicUpdateRequest) {
    return this.client.demographicUpdateDemographic(request);
  }

}
