<div class="page-layout">
  <app-header [user]="user"></app-header> 

  <app-side-nav [session]="session" [sideNaveMenu]="menu"></app-side-nav>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>

  <app-footer [footerMenu]="menu"></app-footer>
</div>