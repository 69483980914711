/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ActionResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface MemberServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param userId 
     * @param newRoleId 
     * @param organizationId 
     */
    memberChangeMemberRole(userId: string, newRoleId: string, organizationId: string, extraHttpRequestParams?: any): Observable<ActionResponse>;

    /**
     * 
     * 
     * @param organizationId 
     * @param userId 
     */
    memberRemoveMember(organizationId: string, userId: string, extraHttpRequestParams?: any): Observable<ActionResponse>;

}
