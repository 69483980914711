/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Platform } from './platform';


export interface BrandPlatform { 
    platform: Platform;
    id: string;
    brandId: string;
    platformName: string;
    createdOn: Date;
    createdBy: string;
    platformUrl?: string;
    brand: Brand;
}

