<div class="menu-wrapper">
    <div class="header">
        <div class="image"></div>
        <div class="w-100 overflow-hidden">
            <p class="name">{{user.name}}</p>
            <p class="email">{{user.email}}</p>
        </div>
    </div>

    <ul>
        <li (click)="selected.emit()">
            <a [href]="accountWebURL" target="_blank">
                Seech Account
            </a>
        </li>
        <li *ngFor="let page of pages" (click)="goToRoute(page.route!)">
            <p>{{page.label}}</p>
        </li>
    </ul>

    <div class="footer" (click)="signout()">
        <i [sch-icon]="'login'"></i>
        <div class="logout">Logout</div>
    </div>
</div>


