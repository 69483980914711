/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { IntentResponse } from '../model/models';
import { Project } from '../model/models';
import { ProjectCreateRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProjectServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param request 
     */
    projectAddProject(request: ProjectCreateRequest, extraHttpRequestParams?: any): Observable<Array<IntentResponse>>;

    /**
     * 
     * 
     * @param request 
     */
    projectUpdateProjectInformation(request: ProjectCreateRequest, extraHttpRequestParams?: any): Observable<Project>;

}
