/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Session } from './session';


export interface Connection { 
    id: string;
    connectionId?: string;
    sessionId: string;
    userId: string;
    isConnected: boolean;
    lastUpdatedOn: Date;
    session: Session;
    user: User;
}

