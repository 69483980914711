<div class="dialog" [attr.hasDivider]="hasDivider">
    <div class="dialog-title">
        <ng-container *ngIf="label">
            <div class="divider"></div>
            <p class="label">{{ label }}</p>
        </ng-container>
            <div class="divider"></div>
            <i [sch-icon]="'cancel'" role="button" (click)="closeModal()"></i>
    </div>
    <p class="label mobile" *ngIf="label">{{ label }}</p>

  
    <div class="dialog-body">
      <ng-content></ng-content>
    </div>
  </div>