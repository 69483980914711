export const ROUTE = {
  OVERVIEW: 'overview',
  HOME: 'home',
  ORGANIZATION: 'organization',
  BRANDS: 'brands',
  PRODUCTS: 'products',
  INTEGRATIONS: 'integrations',
  PROJECTS: 'projects',
  CAMPAIGN: 'campaign',
  SUBSCRIPTION: 'subscription',
  ROOT: '',
  BUILDER_ROUTES: {
    VIDEO_BUILDER: 'video-builder',
    AUDIO_BUILDER: 'audio-builder',
    IMAGE_BUILDER: 'image-builder',
    EMAIL_BUILDER: 'email-builder',
    SMS_BUILDER: 'sms-builder',
    LANDING_PAGE_BUILDER: 'landing-page-builder',
  },
  CAMPAIGN_ROUTES: {
    BASIC_INFO: 'basic-info',
    PRODUCT_INFO: 'product-info',
    OBJECTIVES: 'objectives',
    CHANNELS: 'channels',
    BUILDER: 'builder',
    VIDEO_BUILDER: 'video-builder',
    AUDIO_BUILDER: 'audio-builder',
    IMAGE_BUILDER: 'image-builder',
    EMAIL_BUILDER: 'email-builder',
    SMS_BUILDER: 'sms-builder',
    LANDING_PAGE_BUILDER: 'landing-page-builder',
    PUBLISH_REVIEW: 'publish-review',
    REVIEW_DETAILS: 'review-details',
    PREVIEW_INFORMATION: 'preview-information',
  },

  ORGANIZATION_ROUTES: {
    ABOUT: 'about',
    MEMBERS: 'members',
    DEMOGRAPHICS: 'demographics',
    SETTINGS: 'settings',
  },
};

export const OVERVIEW_FRAGMENTS = {
  home: 'Home',
  creatives: 'Creatives',
  platforms: 'Platforms',
  faq: 'FAQ',
  pricing: 'Pricing',
};
