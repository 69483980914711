import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddButtonComponent } from '../add-button/add-button.component';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ControlsNgModule } from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';

@Component({
  selector: 'app-add-edit-media',
  standalone: true,
  imports: [
    CommonModule, 
    AddButtonComponent, 
    FormsModule,
    IconsNgModule,
    ControlsNgModule
  ],
  templateUrl: './add-edit-media.component.html',
  styleUrl: './add-edit-media.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AddEditMediaComponent)
    }
  ]
})
export class AddEditMediaComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() itemHeight!: string;
  @Input() itemWidth!: string; 
  @Input() multiple = true;
  @Input() id!: string;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() max = 10;

  _value!: FileList | null;
  media: SafeResourceUrl[] = [];
  touched = false;

  get value(): FileList | null {
    return this._value;
  }
  set value(val: FileList | null) {
    this._value = val;
    if(val) {
      this.media = [];
      Array.from(val).forEach(file => {
        const url = URL.createObjectURL(file);
        this.media.push(this.sanitizer.bypassSecurityTrustResourceUrl(url));
      })
    }

  }

  constructor(private sanitizer: DomSanitizer){}

  removeMedia(index: number) {
    this.media = [...this.media.splice(index, 1)];
  }

  onChange = (event: any) => {
    //intentionally not implemented; method added to initialize variable
  };

  onTouched = () => {
    //intentionally not implemented; method added to initialize variable
  };

  writeValue(val:  FileList | null): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
