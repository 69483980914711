import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { OVERVIEW_FRAGMENTS } from '../constants/route';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[appFragmentInView]',
  standalone: true,
})
export class FragmentInViewDirective implements OnInit {
  @Input({required: true}) fragment!: string;
  @Input() headerHeight = 50;
  @Output() fragmentChange: EventEmitter<string> = new EventEmitter<string>();
  currentFragment!: string;

  constructor(private el: ElementRef, private route: ActivatedRoute, private router: Router) {}
  
  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.currentFragment = fragment ?? OVERVIEW_FRAGMENTS.home;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const scroll = this.route.snapshot.queryParams['scroll'];
    if(!scroll) {
      const distanceFromTop = this.el.nativeElement.getBoundingClientRect().top;
      if((-1 * this.headerHeight) <= distanceFromTop && 
        distanceFromTop <= this.headerHeight &&
        this.fragment !== this.currentFragment)  {
        this.router.navigate(["."], {fragment: this.fragment});
      }
    }
  }

}
