/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandMembershipUpdateRequest } from './brandMembershipUpdateRequest';


export interface ManageUserOrganizationRequest { 
    userId: string;
    organizationRoleId: string;
    brandRoleId: string;
    newAdditions?: Array<BrandMembershipUpdateRequest>;
    deletions?: Array<BrandMembershipUpdateRequest>;
    updates?: Array<BrandMembershipUpdateRequest>;
}

